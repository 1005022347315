import './navigation.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Navigation {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-nav',
            rootToggleAttr: 'rootToggle',
            childrenAttr: 'category',
            itemsAttr: 'items',
            scrollerAttr: 'scroller',
            groupAttr: 'data-nav-group',
            toggleAttr: 'toggle',
            closeAttr: 'close',
            levelAttr: 'data-nav-level',
            breakpoint: 1280
        };

        this.settings = Object.assign({}, defaults, options);

        this.$navigation = element;
        this.$navigationToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');
        this.$navigationScroller = this.$navigation.querySelector('[' + this.settings.initAttr + '="' + this.settings.scrollerAttr + '"]');
        this.$navigationToggles = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$navigationMainItems = this.$navigation.querySelectorAll('[data-nav-level="1"], a.main-nav__item--level-1');
        this.$navigationMainWithChildren = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.childrenAttr + '"]');
        this.$body = document.querySelector('body');
        this.closeTimer = null;
        this.currentLevel = 0;
        this.breakpoint = window.matchMedia('(min-width: ' + this.settings.breakpoint + 'px)');

        this.initialize();
    }

    initialize () {
        this.setFirstLevel();
        this.setEvents();
        this.handleTabIndices();
    }

    setEvents () {
        this.$navigation.addEventListener('change', (e) => {
            const $element = e.target;

            if ($element === this.$navigationToggle) {
                if (this.$navigationToggle.checked === true) {
                    disableBodyScroll(this.$navigationScroller);

                    if (!this.breakpoint.matches) {
                        this.addFirstLevelIndices();
                    }
                } else {
                    this.clearAllSubnavs();
                    this.handleTabIndices();
                    enableBodyScroll(this.$navigationScroller);
                }
            }

            if ($element.getAttribute(this.settings.initAttr) === this.settings.toggleAttr) {
                this.$navigationScroller.scrollTo(0, 0);
                const elementLevel = parseInt($element.getAttribute(this.settings.levelAttr));

                this.$body.classList.remove('nav-level--' + this.currentLevel);

                if ($element.checked === true) {
                    this.currentLevel = elementLevel + 1;
                    this.closeOthers($element);
                    this.addSubTabIndices($element);
                } else {
                    this.currentLevel = elementLevel;

                    this.closeSubnav($element);
                    this.removeSubTabIndices($element);
                }

                this.$body.classList.add('nav-level--' + this.currentLevel);
            }
        });

        // show navigation if first level item is clicked
        this.$navigationMainWithChildren.forEach(($rootItem) => {
            $rootItem.addEventListener('change', () => {
                if (this.$navigationToggle.checked === false) {
                    this.$navigationToggle.checked = true;

                    const navigationChangeEvent = new CustomEvent('change', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });

                    this.$navigationToggle.dispatchEvent(navigationChangeEvent);
                }
            });
        });

        // handle focus for desktop nav by focusing level 1 toggle
        this.$navigationMainItems.forEach(($mainItem) => {
            const mainItemType = $mainItem.tagName.toLowerCase();

            let mainItemId = $mainItem.id;
            if (mainItemType === 'a') {
                mainItemId = $mainItem.getAttribute('data-nav-id');
            }

            $mainItem.addEventListener('focus', () => {
                if ($mainItem.matches(':focus-visible')) {
                    if (mainItemType === 'a') {
                        this.toggleLinkFocus(mainItemId, true);
                    } else {
                        this.toggleVisibleFocus(mainItemId, true);
                    }
                }
            });

            $mainItem.addEventListener('blur', () => {
                if (mainItemType === 'a') {
                    this.toggleLinkFocus(mainItemId, false);
                } else {
                    this.toggleVisibleFocus(mainItemId, false);
                }
            });
        });

        // close navigation on outside click
        document.addEventListener('click', (e) => {
            if (e.target.classList.contains('navigation')) {
                this.closeMainnav();
            }
        });

        // close navigation on ESC key
        document.addEventListener('keydown', (e) => {
            let isEscape = false;
            if ('key' in e) {
                isEscape = (e.key === 'Escape' || e.key === 'Esc');
            } else {
                isEscape = (e.keyCode === 27);
            }
            if (isEscape && this.$navigationToggle.checked === true) {
                this.closeMainnav();
            }
        });

        // close navigation on resize
        window.addEventListener('resize', () => {
            // this.closeMainnav();
        });
    }

    closeMainnav () {
        this.$navigationToggle.checked = false;

        const navigationCloseEvent = new CustomEvent('change', {
            view: window,
            bubbles: true,
            cancelable: false
        });

        this.$navigationToggle.dispatchEvent(navigationCloseEvent);
    }

    closeSubnav ($currentToggle) {
        const $currentNav = $currentToggle.closest('[' + this.settings.groupAttr + '="' + this.currentLevel + '"]');
        const subLevel = this.currentLevel + 1;
        const $subNav = $currentNav.querySelectorAll('[' + this.settings.levelAttr + '="' + subLevel + '"]');

        for (let i = 0; i < $subNav.length; i++) {
            $subNav[i].checked = false;
        }
    }

    closeOthers ($currentToggle) {
        const $currentNav = $currentToggle.closest('[' + this.settings.initAttr + '="' + this.settings.itemsAttr + '"]');
        const $siblings = $currentNav.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');

        for (let i = 0; i < $siblings.length; i++) {
            if ($currentToggle !== $siblings[i]) {
                $siblings[i].checked = false;
            }
        }
    }

    clearAllSubnavs () {
        for (let i = 0; i < this.$navigationToggles.length; i++) {
            const $toggle = this.$navigationToggles[i];

            if ($toggle.checked === true) {
                $toggle.checked = false;
            }
        }

        for (let level = 0; level < 5; level++) {
            this.$body.classList.remove('nav-level--' + level);
        }

        this.setFirstLevel();
    }

    setFirstLevel () {
        this.currentLevel = 1;
        this.$body.classList.add('nav-level--' + this.currentLevel);
    }

    toggleVisibleFocus (id, focus) {
        // console.log('toggle focus', id, focus);
        const $labels = this.$navigation.querySelectorAll('[for="' + id + '"]');

        $labels.forEach($label => {
            if (focus === true) {
                $label.classList.add('focus-visible');
            } else {
                $label.classList.remove('focus-visible');
            }
        });
    }

    toggleLinkFocus (id, focus) {
        // console.log('toggle focus', id, focus);
        const $links = this.$navigation.querySelectorAll('[data-nav-id="' + id + '"]');

        $links.forEach($link => {
            if (focus === true) {
                $link.classList.add('focus-visible');
            } else {
                $link.classList.remove('focus-visible');
            }
        });
    }

    handleTabIndices () {
        this.hideAllTabIndices(this.$navigationToggles);
        this.hideAllTabIndices(this.$navigation.querySelectorAll('a'));
        if (this.breakpoint.matches) {
            this.addFirstLevelIndices();
        }
    }

    addFirstLevelIndices () {
        const $mainToggles = this.$navigation.querySelectorAll('[data-nav-level="1"]');
        this.showAllTabIndices($mainToggles);
        const $mainItems = this.$navigation.querySelectorAll('.main-nav__item--level-1');
        this.showAllTabIndices($mainItems);
    }

    hideAllTabIndices ($navToggles) {
        // console.log('hide all tab indices', $navToggles);
        $navToggles.forEach($toggle => {
            $toggle.setAttribute('tabindex', -1);
        });
    }

    showAllTabIndices ($navToggles) {
        // console.log('show all tab indices', $navToggles);
        $navToggles.forEach($toggle => {
            $toggle.removeAttribute('tabindex');
        });
    }

    addSubTabIndices ($currentToggle) {
        // console.log('add sub tab indices', $currentToggle);
        const $subItems = this.getChildNavigation($currentToggle);
        this.showAllTabIndices($subItems);
    }

    removeSubTabIndices ($currentToggle) {
        // console.log('remove sub tab indices', $currentToggle);
        const $subItems = this.getChildNavigation($currentToggle);
        this.hideAllTabIndices($subItems);
    }

    getChildNavigation ($currentToggle) {
        const currentNavLevel = parseInt($currentToggle.getAttribute('data-nav-level'));
        const childNavLevel = currentNavLevel + 1;
        const $currentNav = $currentToggle.closest('[' + this.settings.groupAttr + '="' + currentNavLevel + '"]');
        const $childItems = $currentNav.querySelectorAll('[data-nav-level="' + childNavLevel + '"], [data-nav-item="' + childNavLevel + '"]');

        return $childItems;
    }

    /**
     * hide all focusable elements at first
     * show focusable elements for first level on desktop
     * on select open focusable element
     * show focusable elements for all sub nav items of this element
     * on deselect close focusable element
     * hide focusable elements for all sub nav items of this element
     */
}

export default Navigation;

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context === document) {
        const $navigation = document.querySelector('[data-nav="root"]');
        if ($navigation) {
            const navigationAPI = new Navigation($navigation);
            $navigation.API = navigationAPI;
        }
    }
});
